import React from "react";
import Layout from "../../components/Layout";
import { Heart, ArrowRight, Question, CaretDown } from "phosphor-react";
import { Link } from 'gatsby'

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section is-large">
      <div className="container">
        <div className="content is-flex is-flex-direction-column is-align-content-center" style={{ alignItems: 'center' }}>
          <Heart size={80} weight="bold" color="#000000" />
          <h1>Thank you!</h1>
          <p style={{ textAlign: 'center' }}>We will shoot you an email once we launch. For questions, please shoot us an e-mail at support@cheapin.ph </p>
          <Link href="/" className="button is-large is-black is-rounded is-flex">
            Go Back to Home <ArrowRight size={24} />
          </Link> 
        </div>
      </div>
    </section>
  </Layout>
);
